const header = $('#header');

$(window).on('load', () => {
	
	var windowHeight = $(window).height()
	setCityContainerDimensions(windowHeight)
	setSquareDimensions(windowHeight)

	$( window ).resize(function() {
		var windowHeight = $(window).height()
		setCityContainerDimensions(windowHeight)
		setSquareDimensions(windowHeight)
	});

	$('html, body').stop().animate({
		scrollLeft: 0
	}, 1000, function() {
	})

	renderAnimationElements()

});

function renderAnimationElements() {
	lottie.loadAnimation({
		container: document.getElementById('animationElement_statue'),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/json/animations/statues-animation/data.json' // the path to the animation json
	});

	lottie.loadAnimation({
		container: document.getElementById('animationElement_growth'),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/json/animations/growth-animation/data.json' // the path to the animation json
	});

	lottie.loadAnimation({
		container: document.getElementById('animationElement_mall'),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/json/animations/mall-animation/data.json' // the path to the animation json
	});

	lottie.loadAnimation({
		container: document.getElementById('animationElement_park'),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/json/animations/park-animation/data.json' // the path to the animation json
	});

	lottie.loadAnimation({
		container: document.getElementById('animationElement_bank'),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/json/animations/bank-animation/data.json' // the path to the animation json
	});

	lottie.loadAnimation({
		container: document.getElementById('animationElement_size'),
		renderer: 'svg',
		loop: true,
		autoplay: true,
		path: '/assets/json/animations/size-animation/data.json' // the path to the animation json
	});
}

function setCityContainerDimensions(windowHeight) {
	var widthFactor = 0.1565
	var containerWidth = windowHeight/widthFactor
	$(".cityContainer").css("width", containerWidth)
	$(".helpContainer").css("width", containerWidth)
}

function setSquareDimensions(windowHeight) {
	var squareDimension = windowHeight/100*83
	$(".square").css("height", squareDimension + "px")
	$(".square").css("width", squareDimension/100*80 + "px")
	$(".square").css("margin-top", squareDimension/9.5 + "px")
}

function buildDataOverlay(dialogName, areaName) {
	console.log(dialogName + ", " + areaName) 
	$.getJSON('assets/json/content/' + areaName + '.json', function (data) {
		buildDialog(dialogName, data);
  });
}

function buildDummyOverlay(dialogName, areaName) {
	$("#dataOverlay .dataDialogs").html()	
	console.log(dialogName + ", " + areaName)
	var html = "<img class='dummyDialogImage' src='assets/img/dummyDialogs/" + dialogName + ".png'>"
	html += "<div class='closeButton'></div>"

	if(dialogName === "populationEvolution" ) {
		html += "<div class='slideStatus'><ul><li aid='population' did='ageDistribution'></li><li class='active'></li></ul></div>"
		html += "<div aid='population' did='ageDistribution' id='prevButton'></div>"
	}
	

	$("#dataOverlay .dataDialogs").html(html)	

	$('.closeButton').bind('click', function(event) {
		$("#dataOverlay").removeClass("visible");
		event.preventDefault();
	});

	// Sliderbar TODO
	
	$('.slideStatus li, #prevButton').bind('click', function(event) {
		if( $(this).hasClass("active") === false ) {
			
			var dialogName = $(this).attr("did")
			console.log(dialogName)
			var areaName = $(this).attr("aid")
			buildDataOverlay(dialogName, areaName)
		}
	});

	$("#dataOverlay").addClass("visible")
}

function buildDialog(dialogName, data) {
	$("#dataOverlay .dataDialogs").html()	
	console.log(data);
	var canvasArray = []
	var html = ""

	for (var area = 0; area < 1; area++) {
		var dialogData = data.areas[area]
		html += "<div id='" + dialogData.id + "' class='dataDialog'>"
		// Start Select Container
		html += "<div class='selectContainer'>"
		html += "<h3>" + data.title + "</h3>"
		html += "<h2>" + dialogData.title + "</h2>"
		

		// Subara Select List
		html += "<ul>"
		for (var subarea = 0; subarea < dialogData.subareas.length; subarea++) {
			var subAreaData = dialogData.subareas[subarea]
			html += "<li class='subarea' gid='" + subAreaData.id + "'>"
			html += "<h5>" + subAreaData.title + "</h5>"
			html += "<div class='selectorLabelContainer'>"

			if(subAreaData.labels !== undefined) {
				html += "<h6>" + subAreaData.labels.title + "</h6>"
				html += "<ul class='labelList'>"
				for (var label = 0; label < subAreaData.labels.values.length; label++) {
					var labelData = subAreaData.labels.values[label]
					html += "<li lid='" + label + "'>" +  labelData + "</li>"
				}
				html += "</ul>"
				if (subAreaData.region === true) {
					html += "<h6>Region:</h6>"
					html += "<ul region1='0' class='regionList'><li>Germany</li><li class='plusButton'>+</li></ul>"
				}
			}
			
			html += "</div>"
			html += "</li>"
		}
		html += "</ul>"


		// Region Overlay
		html += "<div max='1' id='regionOverlay'>"
		html += "<h3>Choose one region</h3>"
		html += "<div class='regionSelectList'>"
		html += "<span rid='0'>Germany</span>"
		html += "<span rid='1'>Baden-Württemberg</span>"
		html += "<span rid='2'>Bavaria</span>"
		html += "<span rid='3'>Berlin</span>"
		html += "<span rid='4'>Brandenburg</span>"
		html += "<span rid='5'>Bremen</span>"
		html += "<span rid='6'>Hamburg</span>"
		html += "<span rid='7'>Hesse</span>"
		html += "<span rid='8'>Mecklenburg-Western Pomerania</span>"
		html += "<span rid='9'>Lower Saxony</span>"
		html += "<span rid='10'>North Rhine-Westphalia</span>"
		html += "<span rid='11'>Rhineland-Palatinate</span>"
		html += "<span rid='12'>Saarland</span>"
		html += "<span rid='13'>Saxony</span>"
		html += "<span rid='14'>Saxony-Anhalt</span>"
		html += "<span rid='15'>Schleswig-Holstein</span>"
		html += "<span rid='16'>Thuringia</span>"
		html += "</div>"
		html += "<button>Submit</button>"
		html += "</div>"

		html += "</div>"
		// Ende Select Container

		// Start Data Conainer
		html += "<div class='dataContainer'>"

		for (var subarea = 0; subarea < dialogData.subareas.length; subarea++) {
			var subAreaData = dialogData.subareas[subarea]
			html += "<div id='dataContent_" + subAreaData.id + "' class='dataElement'>"
			html += "<div class='metaContainer'>"
			html += "<div class='sources'>Sources</div>"
			html += "<div class='sourcesOverlay'>" +  subAreaData.sourcesText + "</div>"
			html += "</div>"
			html += "<h4>" + subAreaData.title + "</h4>"
			html += "<p>" + subAreaData.text + "</p>"
			html += "</div>"
		}

		html += "<div class='canvasContainer' id='canvasContainer'></div>"
		html += "<div class='legende'></div>"

		html += "</div>"
		// Emd Data Container 

		html += "</div>"
  }

	// Closebutton
	html += "<div class='closeButton'></div>"

	// Sliderbar TODO
	html += "<div class='slideStatus'><ul><li class='active'></li><li aid='population' did='populationEvolution'></li></ul></div>"
	html += "<div aid='population' did='populationEvolution' id='nextButton'></div>"

	$("#dataOverlay .dataDialogs").html(html)	
	
	$.getJSON('assets/json/content/data/' + data.areas[0].subareas[0].id + '.json', function (data) {
		paintCanvas(data)
	});

	$( ".dataDialogs .dataDialog:nth-child(1)" ).addClass("current")
	$( ".dataContainer .dataElement:nth-child(1)" ).addClass("current")
	$( ".selectContainer li:nth-child(1)" ).addClass("current")
	$( ".labelList li:nth-child(1)" ).addClass("current")
	

	if( dialogName === "ageDistribution") {
		$("#slideNav_ageDistribution").addClass("active")
	} else {
		$("#slideNav_household").addClass("active")
	}

	// Click Events
	bindClickEvents()

	// show Overlay
	$("#dataOverlay").addClass("visible");

}

function setNavPositon() {
	var index = 1;
	for (var i = 1; i < 7; i++) {
		var elementPositon = $( ".square:nth-child(" + i + ")" ).offset().left
		var tiggerPoint = $("html, body").scrollLeft() + $(window).width() * 0.5
		if ( tiggerPoint > elementPositon ) {
			index = i;
		}
	}
	$('.navContainer li').removeClass("current")
	$(".navContainer li:nth-child(" + index + ")").addClass("current")

	setIndicatorPosition(index)
}

function setIndicatorPosition(index) {
	var indicatorPosition = 0;
	for (var i = 1; i < index; i++) {
		indicatorPosition += $( ".navContainer li:nth-child(" + i + ")" ).width() + 80;
  }
	$(".scrollIndicator").css("left", indicatorPosition)
	$(".scrollIndicator").css("width", $( ".navContainer li:nth-child(" + index + ")" ).width() + 80)
}

function bindClickEvents() {

	

	$('.closeButton').bind('click', function(event) {
		$("#dataOverlay").removeClass("visible");
		event.preventDefault();
	});

	$('.selectContainer li').bind('click', function(event) {
		if(event.target === event.currentTarget) return
		$(".selectContainer li").removeClass("current")
		$(this).addClass("current")
		var gid = $(this).attr("gid")
		$(".dataElement").removeClass("current")
		$("#dataContent_" + gid).addClass("current")
		$( ".labelList li:nth-child(1)" ).addClass("current")
		$(this).find(".labelList").find(".current").trigger( "click" );
		// event.preventDefault();
		// event.stopPropagation();
	});

	$('.slideStatus li, #nextButton').bind('click', function(event) {
		if( $(this).hasClass("active") === false ) {
			var dialogName = $(this).attr("did")
			var areaName = $(this).attr("aid")
			buildDummyOverlay(dialogName, areaName)
		}
	});

	$('.labelList li').bind('click', function(event) {
		var jsonId = $('.subarea.current').attr("gid")
		
		event.stopPropagation();

		var lidText = $(this).text()
		var lid = $(this).attr("lid")

		$(".labelList li").removeClass('current')
		$(this).addClass('current')
		
		$.getJSON('assets/json/content/data/' + jsonId + '.json', function (data) {
			
			console.log(lidText)
			if( lidText === "all") {
				var regionNumbers = $('.regionList').attr("region1")
				var selection1 = parseInt($("#half_1 select").val())
				var selection2 = parseInt($("#half_2 select").val())


				fillCanvas(data, [selection1,selection2], "refresh", regionNumbers)
				$("#half_1 select option:first").attr("selected", "selected")
				$("#half_2 select option:last").attr("selected", "selected")
				$("#regionOverlay").attr("max", "1")


				var html = "<li>" + getRegionName($(".regionList").attr("region1")) + "</li>"
				html += "<li class='plusButton'>+</li>"
				$(".regionList").html(html)

		
				// Bind Click on li again
				$('.regionList li').bind('click', function(event) {
					event.stopPropagation();
					$("#regionOverlay").show();
					var leftPosition = $("li.current .regionList li").parent().find(".plusButton").position().left + 100;
					var topPosition = $("li.current .regionList li").parent().position().top + 125 - 185;
					if ( $(".subarea.current").attr("gid") === "percentageOfOldPeopleWithinPopulation") {
						topPosition += 79
					}
					if ( $(".subarea.current").attr("gid") === "dependencyAndAgeRatio") {
						topPosition += 158
					}
					$("#regionOverlay").css("left", leftPosition +  "px")
					$("#regionOverlay").css("top", topPosition +  "px")
					var currentRegionString = $(".regionList").attr("region1")
					var currentRegionString2 = $(".regionList").attr("region2");
					var currentRegionString3 = $(".regionList").attr("region3");

					if (currentRegionString !== "" && currentRegionString !== undefined) {
						console.log("Jetzt wird das gesetzt")
						var regionNumber = parseInt(currentRegionString) + 1
						$( "#regionOverlay span:nth-child(" + regionNumber + ")" ).addClass("current")
						console.log(regionNumber)
					}
					if (currentRegionString2 !== "" && currentRegionString2 !== undefined) {
						var regionNumber = parseInt(currentRegionString2) + 1 
						$( "#regionOverlay span:nth-child(" + regionNumber + ")" ).addClass("current")
						console.log(regionNumber)
					}
					if (currentRegionString3 !== "" && currentRegionString3 !== undefined) {
						var regionNumber = parseInt(currentRegionString3) + 1
						$( "#regionOverlay span:nth-child(" + regionNumber + ")" ).addClass("current")
						console.log(regionNumber)
					}

					var maxNumber = $("#regionOverlay").attr("max")
					if ( maxNumber === "1" ) {
						$("#regionOverlay h3").text("Choose one region")
					} else {
						$("#regionOverlay h3").text("Choose up to 3 regions")
					}
				});


				$("#regionOverlay span").removeClass("current")
				$(".regionList").attr("region2", "")
				$(".regionList").attr("region3", "")
			} else {
				var regionNumbers = []
				var currentRegionString = $(".regionList").attr("region1")
				var currentRegionString2 = $(".regionList").attr("region2");
				var currentRegionString3 = $(".regionList").attr("region3");

				if (currentRegionString !== "" && currentRegionString !== undefined) {
					regionNumbers.push(parseInt(currentRegionString))
				}
				if (currentRegionString2 !== "" && currentRegionString2 !== undefined) {
					regionNumbers.push(parseInt(currentRegionString2))
				}
				if (currentRegionString3 !== "" && currentRegionString3 !== undefined) {
					regionNumbers.push(parseInt(currentRegionString3))
				}
				console.log("HIER:" + lid)
				fillCanvas(data, [lid], "refresh", regionNumbers)
				$("#regionOverlay").attr("max", "3")
			}
		});
		// event.preventDefault();
	});

	$( ".metaContainer .sources" ).mouseover(function() {
		$( ".sourcesOverlay" ).show();
	});
	$( ".metaContainer .sources" ).mouseleave(function() {
		$( ".sourcesOverlay" ).hide();
	});




	$('.regionList li').bind('click', function(event) {
		event.stopPropagation();
		$("#regionOverlay").show();
		var leftPosition = $("li.current .regionList li").parent().find(".plusButton").position().left + 100;
		var topPosition = $("li.current .regionList li").parent().position().top + 125 - 185;
		if ( $(".subarea.current").attr("gid") === "percentageOfOldPeopleWithinPopulation") {
			topPosition += 79
		}
		if ( $(".subarea.current").attr("gid") === "dependencyAndAgeRatio") {
			topPosition += 158
		}
		$("#regionOverlay").css("left", leftPosition +  "px")
		$("#regionOverlay").css("top", topPosition +  "px")
		var currentRegionString = $(".regionList").attr("region1")
		var currentRegionString2 = $(".regionList").attr("region2");
		var currentRegionString3 = $(".regionList").attr("region3");

		if (currentRegionString !== "" && currentRegionString !== undefined) {
			console.log("Jetzt wird das gesetzt")
			var regionNumber = parseInt(currentRegionString) + 1
			$( "#regionOverlay span:nth-child(" + regionNumber + ")" ).addClass("current")
			console.log(regionNumber)
		}
		if (currentRegionString2 !== "" && currentRegionString2 !== undefined) {
			var regionNumber = parseInt(currentRegionString2) + 1 
			$( "#regionOverlay span:nth-child(" + regionNumber + ")" ).addClass("current")
			console.log(regionNumber)
		}
		if (currentRegionString3 !== "" && currentRegionString3 !== undefined) {
			var regionNumber = parseInt(currentRegionString3) + 1
			$( "#regionOverlay span:nth-child(" + regionNumber + ")" ).addClass("current")
			console.log(regionNumber)
		}

		var maxNumber = $("#regionOverlay").attr("max")
		if ( maxNumber === "1" ) {
			$("#regionOverlay h3").text("Choose one region")
		} else {
			$("#regionOverlay h3").text("Choose up to 3 regions")
		}

	});

	$('#regionOverlay span').bind('click', function(event) {
		if ( $(this).hasClass("current") !== true ) {
			var maxNumber = $("#regionOverlay").attr("max")
			console.log(maxNumber)
			if ( maxNumber === "1" ) {
				var regionString = ""
				$('#regionOverlay span').removeClass("current")
				$(this).addClass("current")
				regionString = $(this).attr("rid")
				$(".regionList").attr("region1", regionString)
			} else {
				console.log("JETZT")
				$(this).addClass("current")
				var number = 0;
				$( "#regionOverlay span.current" ).each(function( index ) {
					number = number + 1
					console.log("Nummer: " + $(this).text())
				})
				
				if( number < 4) {
					regionString = $(this).attr("rid")
					$(".regionList").attr("region" + number, regionString)
				} else {
					$(this).removeClass("current")
				}
			}
		}
	});

	$('#regionOverlay button').bind('click', function(event) {
		var regionName = getRegionName($(".regionList").attr("region1"))
		var regionName2 = getRegionName($(".regionList").attr("region2"))
		var regionName3 = getRegionName($(".regionList").attr("region3"))
		var html = "<li>" + regionName + "</li>"
		if (regionName2 !== "" && regionName2 !== undefined) {
			html += "<li>" + regionName2 + "</li>"
		}
		if (regionName3 !== "" && regionName3 !== undefined) {
			html += "<li>" + regionName3 + "</li>"
		}
		html += "<li class='plusButton'>+</li>"
		$(".regionList").html(html)

		// Bind Click on li again
		$('.regionList li').bind('click', function(event) {
			event.stopPropagation();
			$("#regionOverlay").show();
			var leftPosition = $("li.current .regionList li").parent().find(".plusButton").position().left + 100;
			var topPosition = $("li.current .regionList li").parent().position().top + 125 - 185;
			if ( $(".subarea.current").attr("gid") === "percentageOfOldPeopleWithinPopulation") {
				topPosition += 79
			}
			if ( $(".subarea.current").attr("gid") === "dependencyAndAgeRatio") {
				topPosition += 158
			}

			

			$("#regionOverlay").css("left", leftPosition +  "px")
			$("#regionOverlay").css("top", topPosition +  "px")
		});

		var lid = $( "li.current .labelList li.current" ).text();
		if( lid === "all") {
			$( "ul li.current .labelList li.current" ).trigger( "click" );
		} else {
			$( "ul li.current .labelList li.current" ).trigger( "click" );
		}
		$("#regionOverlay").hide()
	});

}

function getRegionName(reginonNumber) {
	var regions = ["Germany", "Baden-Württemberg", "Bavaria", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hesse", "Mecklenburg-Western Pomerania", "Lower Saxony", "North Rhine-Westphalia", "Rhineland-Palatinate", "Saarland", "Saxony", "Saxony-Anhalt", "Schleswig-Holstein", "Thuringia"]
	return regions[reginonNumber]
}




function paintCanvas(data) {
		
	// Build HTML Structur
	var html = "<div id='half_1' class='half'>"
	html += "<canvas id='canvas_1'></canvas>"
	html += "<div class='selectorContainer'>"
	html += "<select>"
	for (var option = 0; option < 17; option++) {
		var jahreszahl = option + 2019
		html += "<option value='"+ option + "'>" + jahreszahl + "</option>"
	}
	html += "</select>"
	html += "</div>"
	html += "</div>"
	html += "<div id='half_2' class='half'>"
	html += "<canvas id='canvas_2'></canvas>"
	html += "<div class='selectorContainer'>"
	html += "<select>"
	for (var option = 0; option < 17; option++) {
		var jahreszahl = option + 2019
		html += "<option value='"+ option + "'>" + jahreszahl + "</option>"
	}
	html += "</select>"
	html += "</div>"
	html += "</div>"
	html += "<div id='full' class='full'>"
	html += "<canvas id='canvas_full'></canvas>"
	html += "</div>"

	$("#canvasContainer").html(html)
	$("#half_2 select option:last").attr("selected", "selected");
	
	var lastElement = data.length - 1
	fillCanvas(data, [0, lastElement], "build")
	
	$('select').change(function(){
		var firstSelect = $('#half_1 select').val()
		var secondSelect = $('#half_2 select').val()
		var regionNumbers = $('.regionList').attr("region1")
		fillCanvas(data, [firstSelect, secondSelect], "refresh", regionNumbers)
	});

}


function fillCanvas(data, selection, action, regionNumbers) {
	
	var backgroundColors = ['#E08B00', '#CC51A5', '#557DA6']
	
	if (action === "build") {

		var canvasData1 = [
			data[selection[0]][0][0],
			data[selection[0]][0][2],
			data[selection[0]][0][3]
		]
		var canvasData2 = [
			data[selection[1]][0][0],
			data[selection[1]][0][2],
			data[selection[1]][0][3]
		]

		var canvasDataFull = [
		]

		for (var year = 0; year < 17; year++) {
			canvasDataFull.push(data[year][0][0])
		}

		console.log(canvasDataFull)

		var config1 = {
			type: 'pie',
			data: {
				labels: ['0-14', '15-64', '65+'],
				datasets: [{
					label: 'Germany',
					backgroundColor: backgroundColors,
					data: canvasData1,
					fill: false
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				title: {
					display: false,
					text: '2019'
				},
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
							label: function(tooltipItems, data) {
								var indice = tooltipItems.index;                 
								return  data.labels[indice] +': '+data.datasets[0].data[indice] + ' %';
							}
					}
				}
			}
		};

		var config2 = {
			type: 'pie',
			data: {
				labels: ['0-14', '15-64', '65+'],
				datasets: [{
					label: 'Germany',
					backgroundColor: backgroundColors,
					data: canvasData2,
					fill: false
				}]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				title: {
					display: false,
					text: '2019'
				},
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
							label: function(tooltipItems, data) {
								var indice = tooltipItems.index;                 
								return  data.labels[indice] +': '+data.datasets[0].data[indice] + ' %';
							}
					}
				}
			}
		};

		var configFull = {
			type: 'line',
			data: {
				labels: ['2019','2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030','2031','2032','2033','2034','2035'],
				datasets: [{
					label: '2019',
					backgroundColor: backgroundColors[0],
					data: canvasDataFull,
					fill: false,
					borderColor: backgroundColors[0],
				},
			]
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				title: {
					display: false,
					text: '2035'
				},
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
							label: function(tooltipItems, data) {
									return tooltipItems.yLabel + ' %'
							}
					}
				},
				scales: {
					xAxes: [{
						display: true,
						scaleLabel: {
							display: false,
							labelString: 'Year'
						}
					}],
					yAxes: [{
						display: true,
						scaleLabel: {
							display: true,
							labelString: 'in %'
						}
					}]
				}
			}
		};
		

		var ctx = document.getElementById('canvas_1' ).getContext('2d');
		window.pie1 = new Chart(ctx, config1);

		var ctx = document.getElementById('canvas_2' ).getContext('2d');
		window.pie2 = new Chart(ctx, config2);

		var ctx = document.getElementById('canvas_full' ).getContext('2d');
		window.line= new Chart(ctx, configFull);

		var legendHtml = "<span class='colorBlock' style='background-color: " + backgroundColors[0] + "'></span><span class='legendText'>0-14</span>"
		legendHtml += "<span class='colorBlock' style='background-color: " + backgroundColors[1] + "'></span><span class='legendText'>15-64</span>"
		legendHtml += "<span class='colorBlock' style='background-color: " + backgroundColors[2] + "'></span><span class='legendText'>65+</span>"
		$(".legende").html(legendHtml)

	} else {


		if (selection.length > 1) {
			regionNumbers = parseInt(regionNumbers)
			console.log(regionNumbers)
			var canvasData1 = [
				data[selection[0]][regionNumbers][0],
				data[selection[0]][regionNumbers][2],
				data[selection[0]][regionNumbers][3]
			]
			var canvasData2 = [
				data[selection[1]][regionNumbers][0],
				data[selection[1]][regionNumbers][2],
				data[selection[1]][regionNumbers][3]
			]

			var newDataObject1 = {
				labels: ['0-14', '15-64', '65+'],
				datasets: [{
					label: 'Germany',
					backgroundColor: backgroundColors,
					data: canvasData1,
					fill: false
				}]
			};

			var newDataObject2 = {
				labels: ['0-14', '15-64', '65+'],
				datasets: [{
					label: 'Germany',
					backgroundColor: backgroundColors,
					data: canvasData2,
					fill: false
				}]
			};

			pie1.data = newDataObject1;
			window.pie1.update();

			pie2.data = newDataObject2;
			window.pie2.update();

			$(".half").show()
			$(".full").hide()

			var legendHtml = "<span class='colorBlock' style='background-color: " + backgroundColors[0] + "'></span><span class='legendText'>0-14</span>"
			legendHtml += "<span class='colorBlock' style='background-color: " + backgroundColors[1] + "'></span><span class='legendText'>15-64</span>"
			legendHtml += "<span class='colorBlock' style='background-color: " + backgroundColors[2] + "'></span><span class='legendText'>65+</span>"
			$(".legende").html(legendHtml)

		} else {
			var dataSets = [];

			for (var i = 0; i < regionNumbers.length; i++) {
				var dataBlockData = []
				for (var year = 0; year < 17; year++) {
					console.log(selection[0])
					dataBlockData.push(data[year][regionNumbers[i]][selection[0]])
				}
				var dataBlock = {
					backgroundColor: backgroundColors[i],
					data: dataBlockData,
					fill: false,
					borderColor: backgroundColors[i],
				}
				dataSets.push(dataBlock)
			}
			
			var newDataObjectFull = {
				labels: ['2019', '2020','2021','2022','2023','2024','2025','2026','2027','2028','2029','2030','2031','2032','2033','2034','2035'],
				datasets: dataSets
			};

			line.data = newDataObjectFull;
			window.line.update();

			$(".full").show()
			$(".half").hide()

			var legendHtml = ""

			for (var e = 0; e < regionNumbers.length; e++) {
				legendHtml += "<span class='colorBlock' style='background-color: " + backgroundColors[e] + "'></span><span class='legendText'>" +  getRegionName(regionNumbers[e]) + "</span>"
			}
			$(".legende").html(legendHtml)
		}

		
	}
	
}