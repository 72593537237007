$('.navContainer li').bind('click', function(event) {
	$('.navContainer li').removeClass("current")
	$(this).addClass("current")
	var anchor = $(this).attr("sid");
	var squareDimension = $("#" + anchor).width()/2
	console.log(squareDimension)
	var offset = $(window).width() / 2 - squareDimension
	/*
	if you want to use one of the easing effects:
	$('html, body').stop().animate({
			scrollLeft: $($anchor.attr('href')).offset().left
	}, 1500,'easeInOutExpo');
	 */
	var scrollPoint = $("#" + anchor).offset().left - offset
	$('html, body').stop().animate({
		scrollLeft: scrollPoint
	}, 2000, function() {
	})
	

	var index = $(".navContainer li").index(this) + 1
	// $(".scrollIndicator").css("left", 200)
	
	setIndicatorPosition(index)
	event.preventDefault();
});

$('.startDialog button').bind('click', function(event) {
	$("#startOverlay").hide();
	event.preventDefault();
});

$('.animationElement').bind('click', function(event) {
	var dialogName = $(this).attr("did")
	var areaName = $(this).attr("aid")
	if(dialogName === "ageDistribution") {
		buildDataOverlay(dialogName, areaName)
	} else {
		buildDummyOverlay(dialogName, areaName)
	}
	event.preventDefault();
});



$('body').on('mousewheel', function(event) {
	var thisScrollPosition = $('html, body').scrollLeft()
	$('html, body').scrollLeft(thisScrollPosition + event.deltaY)
	setNavPositon()
});